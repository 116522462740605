var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.fullNameTitle,
        visible: _vm.dialogVisible,
        width: "700px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "attendanceKid",
          attrs: {
            "label-width": "120px",
            model: _vm.attendanceKid.attendanceArriveKids,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c("el-form-item", [
                      _c(
                        "div",
                        [
                          _vm.attendanceKid.attendanceArriveKids
                            .arriveUrlPicture != null && _vm.showPicutreInitial
                            ? _c("el-image", {
                                staticStyle: {
                                  width: "150px",
                                  height: "150px",
                                  float: "left",
                                  "margin-right": "20px",
                                  "margin-bottom": "10px",
                                },
                                attrs: {
                                  src: _vm.attendanceKid.attendanceArriveKids
                                    .arriveUrlPicture,
                                  fit: "fill",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "avatar-uploader",
                              class: { hideUpload: !_vm.showUpload },
                              attrs: {
                                "show-file-list": true,
                                "list-type": "picture-card",
                                "on-change": _vm.handleChangeUpload,
                                "on-remove": _vm.handleRemove,
                                "auto-upload": false,
                                limit: "1",
                              },
                            },
                            [
                              _vm.attendanceKid.attendanceArriveKids
                                .arriveUrlPicture != null
                                ? _c("span", [
                                    _c(
                                      "i",
                                      {
                                        staticClass: "el-icon-plus",
                                        staticStyle: { "font-size": "20px" },
                                      },
                                      [_vm._v("Cập nhật ảnh")]
                                    ),
                                  ])
                                : _c("span", [
                                    _c(
                                      "i",
                                      {
                                        staticClass: "el-icon-plus",
                                        staticStyle: { "font-size": "20px" },
                                      },
                                      [_vm._v("Thêm ảnh")]
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "22px",
                            color: "#4177e2",
                            "font-size": "17px",
                          },
                        },
                        [
                          _vm._v(
                            "\n              Giờ đến:\n              " +
                              _vm._s(
                                _vm._f("formatTime")(
                                  _vm.attendanceKid.attendanceArriveKids
                                    .timeArriveKid
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 16 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Nội dung", prop: "arriveContent" } },
                      [
                        _c("el-input", {
                          attrs: {
                            rows: 2,
                            type: "textarea",
                            placeholder: "Nhập nội dung",
                          },
                          model: {
                            value:
                              _vm.attendanceKid.attendanceArriveKids
                                .arriveContent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.attendanceKid.attendanceArriveKids,
                                "arriveContent",
                                $$v
                              )
                            },
                            expression:
                              "attendanceKid.attendanceArriveKids.arriveContent",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.attendanceKid.attendanceArriveKids.idCreated != null
                      ? _c("el-form-item", [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "10px" } },
                                [
                                  _vm._v(
                                    "\n                Người tạo điểm danh:\n                " +
                                      _vm._s(
                                        _vm.attendanceKid.attendanceArriveKids
                                          .createdBy
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n                Thời gian tạo:\n                " +
                                    _vm._s(
                                      _vm._f("formatDateTime")(
                                        _vm.attendanceKid.attendanceArriveKids
                                          .createdDate
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                          _vm.attendanceKid.attendanceArriveKids.createdDate !=
                          _vm.attendanceKid.attendanceArriveKids.lastModifieDate
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticStyle: { "line-height": "10px" } },
                                  [
                                    _vm._v(
                                      "\n                Người sửa điểm danh:\n                " +
                                        _vm._s(
                                          _vm.attendanceKid.attendanceArriveKids
                                            .lastModifieBy
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(
                                    "\n                Thời gian sửa:\n                " +
                                      _vm._s(
                                        _vm._f("formatDateTime")(
                                          _vm.attendanceKid.attendanceArriveKids
                                            .lastModifieDate
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("attendanceKid")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("attendanceKid")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }