var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "5px" } },
    [
      _c(
        "div",
        {
          staticClass: "search-input",
          staticStyle: {
            "margin-bottom": "-20px",
            position: "relative",
            "z-index": "1",
          },
        },
        [
          _vm.visibleDetailDate
            ? _c(
                "div",
                [
                  _c("el-date-picker", {
                    staticClass: "input-common input-date",
                    staticStyle: { width: "135px" },
                    attrs: {
                      type: "date",
                      clearable: false,
                      format: "dd-MM-yyyy",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Chọn ngày",
                      "picker-options": _vm.startDayOfWeek,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getSearchAllAttendanceDetail()
                      },
                    },
                    model: {
                      value: _vm.dataSearch.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "date", $$v)
                      },
                      expression: "dataSearch.date",
                    },
                  }),
                  this.getAppTypeUserLogin === "plus"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "input-common input-grade",
                          staticStyle: { width: "140px" },
                          attrs: { placeholder: "Chọn khối" },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangeGrade()
                            },
                          },
                          model: {
                            value: _vm.dataSearch.idGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSearch, "idGrade", $$v)
                            },
                            expression: "dataSearch.idGrade",
                          },
                        },
                        _vm._l(_vm.gradeOfSchoolList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.gradeName },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-class",
                      staticStyle: { width: "140px" },
                      attrs: { placeholder: "Chọn lớp" },
                      on: {
                        change: function ($event) {
                          return _vm.getSearchAllAttendanceDetail()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.idClass,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "idClass", $$v)
                        },
                        expression: "dataSearch.idClass",
                      },
                    },
                    _vm._l(_vm.classList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.className },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-status",
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: "Chọn trạng thái", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.getSearchAllAttendanceDetail()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.absentStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "absentStatus", $$v)
                        },
                        expression: "dataSearch.absentStatus",
                      },
                    },
                    _vm._l(_vm.statusGoToSchoolList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm.visibleAttendanceArrive
            ? _c(
                "div",
                [
                  _c("el-date-picker", {
                    staticClass: "input-common input-date",
                    staticStyle: { width: "135px" },
                    attrs: {
                      clearable: false,
                      type: "date",
                      format: "dd-MM-yyyy",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Chọn ngày",
                      "picker-options": _vm.startDayOfWeek,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.checkAbsentDateInClass("arrive")
                      },
                    },
                    model: {
                      value: _vm.dataSearch.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "date", $$v)
                      },
                      expression: "dataSearch.date",
                    },
                  }),
                  this.getAppTypeUserLogin == "plus"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "input-common input-grade",
                          staticStyle: { width: "140px" },
                          attrs: { placeholder: "Chọn khối" },
                          on: {
                            change: function ($event) {
                              return _vm.changeGradeArrive()
                            },
                          },
                          model: {
                            value: _vm.dataSearch.idGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSearch, "idGrade", $$v)
                            },
                            expression: "dataSearch.idGrade",
                          },
                        },
                        _vm._l(_vm.gradeOfSchoolList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.gradeName },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-class",
                      staticStyle: { width: "140px" },
                      attrs: { placeholder: "Chọn lớp" },
                      on: {
                        change: function ($event) {
                          return _vm.checkAbsentDateInClass("arrive")
                        },
                      },
                      model: {
                        value: _vm.dataSearch.idClass,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "idClass", $$v)
                        },
                        expression: "dataSearch.idClass",
                      },
                    },
                    _vm._l(_vm.classList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.className },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-status",
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: "Chọn trạng thái", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.checkAbsentDateInClass("arrive")
                        },
                      },
                      model: {
                        value: _vm.dataSearch.absentStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "absentStatus", $$v)
                        },
                        expression: "dataSearch.absentStatus",
                      },
                    },
                    _vm._l(_vm.statusGoToSchoolList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm.visibleAtendanceLeave
            ? _c(
                "div",
                [
                  _c("el-date-picker", {
                    staticClass: "input-common input-date",
                    staticStyle: { width: "135px" },
                    attrs: {
                      clearable: false,
                      type: "date",
                      format: "dd-MM-yyyy",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Chọn ngày",
                      "picker-options": _vm.startDayOfWeek,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.checkAbsentDateInClass("leave")
                      },
                    },
                    model: {
                      value: _vm.dataSearch.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "date", $$v)
                      },
                      expression: "dataSearch.date",
                    },
                  }),
                  this.getAppTypeUserLogin == "plus"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "input-common input-grade",
                          staticStyle: { width: "140px" },
                          attrs: { placeholder: "Chọn khối" },
                          on: {
                            change: function ($event) {
                              return _vm.changeGradeLeave()
                            },
                          },
                          model: {
                            value: _vm.dataSearch.idGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSearch, "idGrade", $$v)
                            },
                            expression: "dataSearch.idGrade",
                          },
                        },
                        _vm._l(_vm.gradeOfSchoolList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.gradeName },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-class",
                      staticStyle: { width: "140px" },
                      attrs: { filterable: "", placeholder: "Chọn lớp" },
                      on: {
                        change: function ($event) {
                          return _vm.checkAbsentDateInClass("leave")
                        },
                      },
                      model: {
                        value: _vm.dataSearch.idClass,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "idClass", $$v)
                        },
                        expression: "dataSearch.idClass",
                      },
                    },
                    _vm._l(_vm.classList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.className },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-status",
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: "Chọn trạng thái", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.checkAbsentDateInClass("leave")
                        },
                      },
                      model: {
                        value: _vm.dataSearch.absentStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "absentStatus", $$v)
                        },
                        expression: "dataSearch.absentStatus",
                      },
                    },
                    _vm._l(_vm.statusGoToSchoolList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm.visibleAttendanceEat
            ? _c(
                "div",
                [
                  _c("el-date-picker", {
                    staticClass: "input-common input-date",
                    staticStyle: { width: "135px" },
                    attrs: {
                      type: "date",
                      clearable: false,
                      format: "dd-MM-yyyy",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Chọn ngày",
                      "picker-options": _vm.startDayOfWeek,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getSearchAllAttendanceEat()
                      },
                    },
                    model: {
                      value: _vm.dataSearch.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "date", $$v)
                      },
                      expression: "dataSearch.date",
                    },
                  }),
                  this.getAppTypeUserLogin == "plus"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "input-common input-grade",
                          staticStyle: { width: "140px" },
                          attrs: { placeholder: "Chọn khối" },
                          on: {
                            change: function ($event) {
                              return _vm.changeGradeEat()
                            },
                          },
                          model: {
                            value: _vm.dataSearch.idGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSearch, "idGrade", $$v)
                            },
                            expression: "dataSearch.idGrade",
                          },
                        },
                        _vm._l(_vm.gradeOfSchoolList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.gradeName },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-class",
                      staticStyle: { width: "140px" },
                      attrs: { filterable: "", placeholder: "Chọn lớp" },
                      on: {
                        change: function ($event) {
                          return _vm.getSearchAllAttendanceEat()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.idClass,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "idClass", $$v)
                        },
                        expression: "dataSearch.idClass",
                      },
                    },
                    _vm._l(_vm.classList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.className },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-status",
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: "Chọn trạng thái", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.getSearchAllAttendanceEat()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.absentStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "absentStatus", $$v)
                        },
                        expression: "dataSearch.absentStatus",
                      },
                    },
                    _vm._l(_vm.statusGoToSchoolList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "content-main" }, [
        _c(
          "div",
          [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: { "tab-click": _vm.handleClickTab },
                model: {
                  value: _vm.activeTabName,
                  callback: function ($$v) {
                    _vm.activeTabName = $$v
                  },
                  expression: "activeTabName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "Chi tiết ngày", name: "detailDate" } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingData,
                            expression: "loadingData",
                          },
                        ],
                        ref: "multipleTable",
                        attrs: {
                          "empty-text": _vm.textTable,
                          "element-loading-text": _vm.$tableLoading,
                          "element-loading-spinner": "el-icon-loading",
                          "element-loading-background": "rgba(255,255,255, 0)",
                          data: _vm.attendanceDetailDateList,
                          "cell-style": _vm.tableRowStyleDetail,
                          "header-cell-style": _vm.tableHeaderColor,
                          "max-height": _vm.$tableMaxHeight,
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "55",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            fixed: "",
                            type: "index",
                            label: "STT",
                            width: "50",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            fixed: "",
                            prop: "kids.fullName",
                            label: "Họ tên",
                            "min-width": "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "maClass.className",
                            label: "Lớp",
                            "min-width": "130",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "Điểm danh đến",
                            "min-width": "130",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.attendanceArrive
                                    ? _c("span", [_vm._v("Đã điểm danh")])
                                    : _c("span", [_vm._v("Chưa điểm danh")]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.configSchoolCommon.morningAttendanceArrive
                          ? _c(
                              "el-table-column",
                              { attrs: { label: "Sáng", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Đi học",
                                    align: "center",
                                    width: "90",
                                    header: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .morning
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-check",
                                                  staticStyle: {
                                                    color: "#409eff",
                                                    "font-size": "18px",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3071767864
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Có phép",
                                    align: "center",
                                    width: "90",
                                    header: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .morningYes
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-close",
                                                  staticStyle: {
                                                    color: "#f56c6c",
                                                    "font-size": "18px",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4094552460
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Không phép",
                                    align: "center",
                                    width: "95",
                                    header: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .morningNo
                                              ? _c("i", {
                                                  staticClass: "el-icon-error",
                                                  staticStyle: {
                                                    color: "#f56c6c",
                                                    "font-size": "18px",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2563025619
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.configSchoolCommon.afternoonAttendanceArrive
                          ? _c(
                              "el-table-column",
                              { attrs: { label: "Chiều", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Đi học",
                                    align: "center",
                                    width: "90",
                                    header: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .afternoon
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-check",
                                                  staticStyle: {
                                                    "font-size": "18px",
                                                    color: "#409eff",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    411040578
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Có phép",
                                    align: "center",
                                    width: "90",
                                    header: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .afternoonYes
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-close",
                                                  staticStyle: {
                                                    color: "#f56c6c",
                                                    "font-size": "18px",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2862770422
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Không phép",
                                    align: "center",
                                    width: "95",
                                    header: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .afternoonNo
                                              ? _c("i", {
                                                  staticClass: "el-icon-error",
                                                  staticStyle: {
                                                    color: "#f56c6c",
                                                    "font-size": "18px",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3193589865
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.configSchoolCommon.eveningAttendanceArrive
                          ? _c(
                              "el-table-column",
                              { attrs: { label: "Tối", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Đi học",
                                    align: "center",
                                    width: "90",
                                    header: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .evening
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-check",
                                                  staticStyle: {
                                                    "font-size": "18px",
                                                    color: "#409eff",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1250895902
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Có phép",
                                    align: "center",
                                    width: "90",
                                    header: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .eveningYes
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-close",
                                                  staticStyle: {
                                                    color: "#f56c6c",
                                                    "font-size": "18px",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3389384874
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "Không phép",
                                    align: "center",
                                    width: "95",
                                    header: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .eveningNo
                                              ? _c("i", {
                                                  staticClass: "el-icon-error",
                                                  staticStyle: {
                                                    color: "#f56c6c",
                                                    "font-size": "18px",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1439299509
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: "Giờ đến",
                            width: "90",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatTime")(
                                          scope.row.attendanceArriveKids
                                            .timeArriveKid
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "Giờ về",
                            width: "90",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatTime")(
                                          scope.row.attendanceLeaveKids
                                            .timeLeaveKid
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "Đón muộn (phút)",
                            width: "150",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.attendanceLeaveKids
                                    .minutePickupLate > 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.attendanceLeaveKids
                                              .minutePickupLate
                                          )
                                        ),
                                      ])
                                    : _c("span"),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "Tác vụ",
                            fixed: "right",
                            width: "100",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: !scope.row.attendanceArrive,
                                        size: "mini",
                                        type: "success",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowDetailDate(
                                            scope.$index,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("button.view")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { float: "right", margin: "15px 0" } },
                      [
                        _c(
                          "el-dropdown",
                          { on: { command: _vm.handleCommand } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "button-bottom",
                                attrs: { type: "success" },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("button.exportFile")) +
                                    "\n                "
                                ),
                                _c("i", {
                                  staticClass: "el-icon-caret-bottom",
                                }),
                              ]
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "exportDate" } },
                                  [_vm._v("Xuất theo ngày")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "exportMonth" } },
                                  [_vm._v("Xuất theo tháng\n                ")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "exportCustom" } },
                                  [
                                    _vm._v(
                                      "Xuất file tổng hợp\n                "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "exportMonthDetail" } },
                                  [
                                    _vm._v(
                                      "Xuất file chi tiết tháng\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.checkPermission(["studentQuality_attendance_update"])
                  ? _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "Điểm danh đến",
                          name: "attendanceArriveTab",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingData,
                                expression: "loadingData",
                              },
                            ],
                            ref: "multipleTable",
                            attrs: {
                              "empty-text": _vm.textTable,
                              "element-loading-text": _vm.$tableLoading,
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background":
                                "rgba(255,255,255, 0)",
                              data: _vm.attendanceArriveList,
                              "cell-style": _vm.tableRowStyleDetail,
                              "header-cell-style": _vm.tableHeaderColor,
                              "max-height": _vm.$tableMaxHeight,
                              border: "",
                            },
                            on: {
                              "selection-change":
                                _vm.handleSelectionChangeArrive,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                align: "center",
                                width: "55",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "STT",
                                align: "center",
                                width: "50",
                                fixed: "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "kids.fullName",
                                label: "Họ tên",
                                "min-width": "160",
                                fixed: "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "maClass.className",
                                label: "Lớp",
                                "min-width": "130",
                              },
                            }),
                            _vm.configSchoolCommon.morningAttendanceArrive ||
                            _vm.configSchoolCommon.afternoonAttendanceArrive ||
                            _vm.configSchoolCommon.eveningAttendanceArrive
                              ? _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "Cả ngày",
                                      align: "center",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Đi học")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllDayMothod(
                                                        scope.$index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.checkboxAllDay1,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllDay1 = $$v
                                                    },
                                                    expression:
                                                      "checkboxAllDay1",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowAllDay(
                                                        1,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .allDay,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "allDay",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.allDay",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        322481785
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Có phép")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllDayMothod(
                                                        scope.$index,
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.checkboxAllDay2,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllDay2 = $$v
                                                    },
                                                    expression:
                                                      "checkboxAllDay2",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowAllDay(
                                                        2,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .allDayYes,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "allDayYes",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.allDayYes",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1958815515
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Ko phép")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllDayMothod(
                                                        scope.$index,
                                                        3
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.checkboxAllDay3,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllDay3 = $$v
                                                    },
                                                    expression:
                                                      "checkboxAllDay3",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowAllDay(
                                                        3,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .allDayNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "allDayNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.allDayNo",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1121931840
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configSchoolCommon.morningAttendanceArrive
                              ? _c(
                                  "el-table-column",
                                  { attrs: { label: "Sáng", align: "center" } },
                                  [
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Đi học")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllMorning(
                                                        scope.$index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.checkboxAllMorning1,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllMorning1 =
                                                        $$v
                                                    },
                                                    expression:
                                                      "checkboxAllMorning1",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowMorningDay(
                                                        1,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .morning,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "morning",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.morning",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2877566288
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Có phép")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllMorning(
                                                        scope.$index,
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.checkboxAllMorning2,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllMorning2 =
                                                        $$v
                                                    },
                                                    expression:
                                                      "checkboxAllMorning2",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowMorningDay(
                                                        2,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .morningYes,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "morningYes",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.morningYes",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2157203378
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Ko phép")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllMorning(
                                                        scope.$index,
                                                        3
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.checkboxAllMorning3,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllMorning3 =
                                                        $$v
                                                    },
                                                    expression:
                                                      "checkboxAllMorning3",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowMorningDay(
                                                        3,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .morningNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "morningNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.morningNo",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        305366089
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configSchoolCommon.afternoonAttendanceArrive
                              ? _c(
                                  "el-table-column",
                                  {
                                    attrs: { label: "Chiều", align: "center" },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Đi học")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllAfternoon(
                                                        scope.$index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.checkboxAllAfternoon1,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllAfternoon1 =
                                                        $$v
                                                    },
                                                    expression:
                                                      "checkboxAllAfternoon1",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowAfternoonDay(
                                                        1,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .afternoon,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "afternoon",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.afternoon",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1287218128
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Có phép")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllAfternoon(
                                                        scope.$index,
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.checkboxAllAfternoon2,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllAfternoon2 =
                                                        $$v
                                                    },
                                                    expression:
                                                      "checkboxAllAfternoon2",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowAfternoonDay(
                                                        2,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .afternoonYes,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "afternoonYes",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.afternoonYes",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        800984178
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Ko phép")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllAfternoon(
                                                        scope.$index,
                                                        3
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.checkboxAllAfternoon3,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllAfternoon3 =
                                                        $$v
                                                    },
                                                    expression:
                                                      "checkboxAllAfternoon3",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowAfternoonDay(
                                                        3,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .afternoonNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "afternoonNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.afternoonNo",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1803847881
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configSchoolCommon.eveningAttendanceArrive
                              ? _c(
                                  "el-table-column",
                                  { attrs: { label: "Tối", align: "center" } },
                                  [
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Đi học")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllEvening(
                                                        scope.$index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.checkboxAllEvening1,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllEvening1 =
                                                        $$v
                                                    },
                                                    expression:
                                                      "checkboxAllEvening1",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowEveningDay(
                                                        1,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .evening,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "evening",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.evening",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2634161360
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Có phép")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllEvening(
                                                        scope.$index,
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.checkboxAllEvening2,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllEvening2 =
                                                        $$v
                                                    },
                                                    expression:
                                                      "checkboxAllEvening2",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowEveningDay(
                                                        2,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .eveningYes,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "eveningYes",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.eveningYes",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4261937010
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: { align: "center", width: "60" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "second-title",
                                                  },
                                                  [_vm._v("Ko phép")]
                                                ),
                                                _c("br"),
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeAllEvening(
                                                        scope.$index,
                                                        3
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.checkboxAllEvening3,
                                                    callback: function ($$v) {
                                                      _vm.checkboxAllEvening3 =
                                                        $$v
                                                    },
                                                    expression:
                                                      "checkboxAllEvening3",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRowEveningDay(
                                                        3,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .eveningNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "eveningNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.eveningNo",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1143174729
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configSchoolCommon.morningAttendanceArrive ||
                            _vm.configSchoolCommon.afternoonAttendanceArrive ||
                            _vm.configSchoolCommon.eveningAttendanceArrive
                              ? _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "Giờ đến",
                                    width: "132",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            !scope.row.attendanceArriveKids
                                              .morning &&
                                            !scope.row.attendanceArriveKids
                                              .afternoon &&
                                            !scope.row.attendanceArriveKids
                                              .evening
                                              ? _c("el-time-picker", {
                                                  staticStyle: { width: "85%" },
                                                  attrs: {
                                                    type: "time",
                                                    "value-format": "HH:mm",
                                                    format: "HH:mm",
                                                    size: "mini",
                                                    disabled: "",
                                                    "picker-options": {
                                                      selectableRange:
                                                        "06:00:00 - 21:00:00",
                                                    },
                                                    placeholder: "Chọn",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .timeArriveKid,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "timeArriveKid",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.timeArriveKid",
                                                  },
                                                })
                                              : _c("el-time-picker", {
                                                  staticStyle: { width: "85%" },
                                                  attrs: {
                                                    type: "time",
                                                    "value-format": "HH:mm",
                                                    format: "HH:mm",
                                                    size: "mini",
                                                    "picker-options": {
                                                      selectableRange:
                                                        "06:00:00 - 21:00:00",
                                                    },
                                                    placeholder: "Chọn",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .attendanceArriveKids
                                                        .timeArriveKid,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row
                                                          .attendanceArriveKids,
                                                        "timeArriveKid",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attendanceArriveKids.timeArriveKid",
                                                  },
                                                }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2559796062
                                  ),
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                fixed: "right",
                                label: "Tác vụ",
                                width: "150",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm.configSchoolCommon
                                          .morningAttendanceArrive ||
                                        _vm.configSchoolCommon
                                          .afternoonAttendanceArrive ||
                                        _vm.configSchoolCommon
                                          .eveningAttendanceArrive
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-right": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleArriveRowSave(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("button.save")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                scope.row.attendanceArriveKids
                                                  .timeArriveKid == null ||
                                                !scope.row.attendanceArrive
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          disabled: "",
                                                          size: "mini",
                                                          type: "success",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "button.update"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "success",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleArriveUpdateRowMethod(
                                                              scope.$index,
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "button.update"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3517157721
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { float: "right", margin: "15px 0" } },
                          [
                            _vm.configSchoolCommon.morningAttendanceArrive ||
                            _vm.configSchoolCommon.afternoonAttendanceArrive ||
                            _vm.configSchoolCommon.eveningAttendanceArrive
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "button-bottom",
                                    attrs: {
                                      type: "success",
                                      loading: _vm.loadingButtonSaveArriveDate,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleArriveManyRowSave()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-circle-check",
                                    }),
                                    _vm._v(
                                      "\n              Lưu điểm danh đến\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown",
                              { on: { command: _vm.handleCommand } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "button-bottom",
                                    attrs: { type: "success" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("button.exportFile")) +
                                        "\n                "
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-caret-bottom",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "exportDate" } },
                                      [_vm._v("Xuất theo ngày")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "exportMonth" } },
                                      [
                                        _vm._v(
                                          "Xuất theo tháng\n                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "exportCustom" } },
                                      [
                                        _vm._v(
                                          "Xuất file tổng hợp\n                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: { command: "exportMonthDetail" },
                                      },
                                      [
                                        _vm._v(
                                          "Xuất file chi tiết tháng\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkPermission(["studentQuality_attendance_update"])
                  ? _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "Điểm danh về",
                          name: "atendanceLeave",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingData,
                                expression: "loadingData",
                              },
                            ],
                            ref: "multipleTable",
                            attrs: {
                              "empty-text": _vm.textTable,
                              "element-loading-text": _vm.$tableLoading,
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background":
                                "rgba(255,255,255, 0)",
                              data: _vm.attendanceLeaveList,
                              "cell-style": _vm.tableRowStyleLeave,
                              "header-cell-style": _vm.tableHeaderColor,
                              "max-height": _vm.$tableMaxHeight,
                              border: "",
                            },
                            on: {
                              "selection-change":
                                _vm.handleSelectionChangeLeave,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                align: "center",
                                width: "55",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "STT",
                                align: "center",
                                width: "50",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "kids.fullName",
                                label: "Họ tên",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "maClass.className",
                                label: "Lớp",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Điểm danh về",
                                "min-width": "130",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.attendanceLeave
                                          ? _c("span", [_vm._v("Đã điểm danh")])
                                          : _c("span", [
                                              _vm._v("Chưa điểm danh"),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2179224521
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { align: "center", width: "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                Đã đón\n                "
                                        ),
                                        _c("br"),
                                        _c("el-checkbox", {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeCheckboxAllLeave(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.checkboxAllLeave,
                                            callback: function ($$v) {
                                              _vm.checkboxAllLeave = $$v
                                            },
                                            expression: "checkboxAllLeave",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.attendanceArrive &&
                                        (scope.row.attendanceArriveKids
                                          .morning ||
                                          scope.row.attendanceArriveKids
                                            .afternoon ||
                                          scope.row.attendanceArriveKids
                                            .evening)
                                          ? _c("el-checkbox", {
                                              on: {
                                                change: function ($event) {
                                                  return _vm.setNullTimeLeaveMethod(
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  scope.row.attendanceLeaveKids
                                                    .statusLeave,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row
                                                      .attendanceLeaveKids,
                                                    "statusLeave",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.attendanceLeaveKids.statusLeave",
                                              },
                                            })
                                          : _c("el-checkbox", {
                                              attrs: { disabled: "" },
                                            }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                659897767
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "Giờ về",
                                width: "132",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.attendanceLeaveKids
                                          .statusLeave
                                          ? _c("el-time-picker", {
                                              staticStyle: { width: "85%" },
                                              attrs: {
                                                type: "time",
                                                "value-format": "HH:mm",
                                                format: "HH:mm",
                                                size: "mini",
                                                "picker-options": {
                                                  selectableRange:
                                                    "06:00:00 - 21:00:00",
                                                },
                                                placeholder: "Chọn",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeTimeLeave(
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  scope.row.attendanceLeaveKids
                                                    .timeLeaveKid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row
                                                      .attendanceLeaveKids,
                                                    "timeLeaveKid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.attendanceLeaveKids.timeLeaveKid",
                                              },
                                            })
                                          : _c("el-time-picker", {
                                              staticStyle: { width: "83%" },
                                              attrs: {
                                                type: "time",
                                                "value-format": "HH:mm",
                                                format: "HH:mm",
                                                disabled: "",
                                                size: "mini",
                                                "picker-options": {
                                                  selectableRange:
                                                    "06:00:00 - 21:00:00",
                                                },
                                                placeholder: "Chọn",
                                              },
                                              model: {
                                                value:
                                                  scope.row.attendanceLeaveKids
                                                    .timeLeaveKid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row
                                                      .attendanceLeaveKids,
                                                    "timeLeaveKid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.attendanceLeaveKids.timeLeaveKid",
                                              },
                                            }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4185728057
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Đón muộn(phút)",
                                width: "150",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.attendanceLeaveKids
                                          .minutePickupLate > 0
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.attendanceLeaveKids
                                                    .minutePickupLate
                                                )
                                              ),
                                            ])
                                          : _c("span"),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1403028893
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Tác vụ",
                                fixed: "right",
                                width: "150",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.attendanceArrive &&
                                        (scope.row.attendanceArriveKids
                                          .morning ||
                                          scope.row.attendanceArriveKids
                                            .afternoon ||
                                          scope.row.attendanceArriveKids
                                            .evening)
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleLeaveRowSave(
                                                      scope.$index,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("button.save")
                                                  ) + "\n                "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled: "",
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("button.save")
                                                  ) + "\n                "
                                                ),
                                              ]
                                            ),
                                        scope.row.attendanceLeave &&
                                        scope.row.attendanceLeaveKids
                                          .timeLeaveKid != null
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "success",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleLeaveUpdateRowMethod(
                                                      scope.$index,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("button.update")
                                                  ) + "\n                "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled: "",
                                                  size: "mini",
                                                  type: "success",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("button.update")
                                                  ) + "\n                "
                                                ),
                                              ]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3284620055
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { float: "right", margin: "15px 0" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "button-bottom",
                                attrs: {
                                  type: "success",
                                  loading: _vm.loadingButtonSaveLeaveDate,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleLeaveManyRowSave()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-check",
                                }),
                                _vm._v(
                                  "\n              Lưu điểm danh về\n            "
                                ),
                              ]
                            ),
                            _c(
                              "el-dropdown",
                              { on: { command: _vm.handleCommand } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "button-bottom",
                                    attrs: { type: "success" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("button.exportFile")) +
                                        "\n                "
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-caret-bottom",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "exportDate" } },
                                      [_vm._v("Xuất theo ngày")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "exportMonth" } },
                                      [
                                        _vm._v(
                                          "Xuất theo tháng\n                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "exportCustom" } },
                                      [
                                        _vm._v(
                                          "Xuất file tổng hợp\n                "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: { command: "exportMonthDetail" },
                                      },
                                      [
                                        _vm._v(
                                          "Xuất file chi tiết tháng\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "Điểm danh ăn", name: "attendanceEat" } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingData,
                            expression: "loadingData",
                          },
                        ],
                        ref: "multipleTable",
                        attrs: {
                          "empty-text": _vm.textTable,
                          "element-loading-text": _vm.$tableLoading,
                          "element-loading-spinner": "el-icon-loading",
                          "element-loading-background": "rgba(255,255,255, 0)",
                          data: _vm.attendanceEatList,
                          "cell-style": _vm.tableRowStyleEat,
                          "header-cell-style": _vm.tableHeaderColor,
                          "max-height": _vm.$tableMaxHeight,
                          border: "",
                        },
                        on: {
                          "selection-change": _vm.handleSelectionChangeEat,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            align: "center",
                            width: "55",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            label: "STT",
                            align: "center",
                            width: "50",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "kids.fullName",
                            sortable: "",
                            label: "Họ tên",
                            "min-width": "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "maClass.className",
                            label: "Lớp",
                            "min-width": "130",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "Điểm danh ăn",
                            "min-width": "130",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.attendanceEat
                                    ? _c("span", [_vm._v("Đã điểm danh")])
                                    : _c("span", [_vm._v("Chưa điểm danh")]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "Trạng thái đi học",
                              align: "center",
                            },
                          },
                          [
                            _vm.configSchoolCommon.morningAttendanceArrive
                              ? _c("el-table-column", {
                                  attrs: {
                                    label: "Sáng",
                                    width: "70",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .morning
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content: "Đi học",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-check",
                                                          staticStyle: {
                                                            color: "#409eff",
                                                            "font-size": "18px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : scope.row.attendanceArriveKids
                                                  .morningYes
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Nghỉ có phép",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-close",
                                                          staticStyle: {
                                                            color: "#f56c6c",
                                                            "font-size": "18px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : scope.row.attendanceArriveKids
                                                  .morningNo
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Nghỉ không phép",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-error",
                                                          staticStyle: {
                                                            color: "#f56c6c",
                                                            "font-size": "18px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2549741626
                                  ),
                                })
                              : _vm._e(),
                            _vm.configSchoolCommon.afternoonAttendanceArrive
                              ? _c("el-table-column", {
                                  attrs: {
                                    label: "Chiều",
                                    width: "70",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .afternoon
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content: "Đi học",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-check",
                                                          staticStyle: {
                                                            color: "#409eff",
                                                            "font-size": "18px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : scope.row.attendanceArriveKids
                                                  .afternoonYes
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Nghỉ có phép",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-close",
                                                          staticStyle: {
                                                            color: "#f56c6c",
                                                            "font-size": "18px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : scope.row.attendanceArriveKids
                                                  .afternoonNo
                                              ? _c(
                                                  "span",
                                                  { staticClass: "absent-no" },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Nghỉ không phép",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-error",
                                                          staticStyle: {
                                                            color: "#f56c6c",
                                                            "font-size": "18px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3253293061
                                  ),
                                })
                              : _vm._e(),
                            _vm.configSchoolCommon.eveningAttendanceArrive
                              ? _c("el-table-column", {
                                  attrs: {
                                    label: "Tối",
                                    width: "70",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.attendanceArriveKids
                                              .evening
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content: "Đi học",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-check",
                                                          staticStyle: {
                                                            color: "#409eff",
                                                            "font-size": "18px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : scope.row.attendanceArriveKids
                                                  .eveningYes
                                              ? _c(
                                                  "span",
                                                  { staticClass: "absent-yes" },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Nghỉ có phép",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-close",
                                                          staticStyle: {
                                                            color: "#f56c6c",
                                                            "font-size": "18px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : scope.row.attendanceArriveKids
                                                  .eveningNo
                                              ? _c(
                                                  "span",
                                                  { staticClass: "absent-no" },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Nghỉ không phép",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-error",
                                                          staticStyle: {
                                                            color: "#f56c6c",
                                                            "font-size": "18px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    342195186
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.configSchoolCommon.morningEat
                          ? _c("el-table-column", {
                              attrs: { align: "center", width: "60" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                Sáng\n                "
                                        ),
                                        _c("br"),
                                        _c("el-checkbox", {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeCheckboxAllEat(
                                                "breakfast",
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.checkboxAllEatBreakfast,
                                            callback: function ($$v) {
                                              _vm.checkboxAllEatBreakfast = $$v
                                            },
                                            expression:
                                              "checkboxAllEatBreakfast",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-checkbox", {
                                          model: {
                                            value:
                                              scope.row.attendanceEatKids
                                                .breakfast,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row.attendanceEatKids,
                                                "breakfast",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.attendanceEatKids.breakfast",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2778031883
                              ),
                            })
                          : _vm._e(),
                        _vm.configSchoolCommon.secondMorningEat
                          ? _c("el-table-column", {
                              attrs: { align: "center", width: "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                Phụ sáng\n                "
                                        ),
                                        _c("br"),
                                        _c("el-checkbox", {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeCheckboxAllEat(
                                                "secondBreakfast",
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.checkboxAllEatSecondBreakfast,
                                            callback: function ($$v) {
                                              _vm.checkboxAllEatSecondBreakfast =
                                                $$v
                                            },
                                            expression:
                                              "checkboxAllEatSecondBreakfast",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-checkbox", {
                                          model: {
                                            value:
                                              scope.row.attendanceEatKids
                                                .secondBreakfast,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row.attendanceEatKids,
                                                "secondBreakfast",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.attendanceEatKids.secondBreakfast",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                786559750
                              ),
                            })
                          : _vm._e(),
                        _vm.configSchoolCommon.lunchEat
                          ? _c("el-table-column", {
                              attrs: { align: "center", width: "60" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                Trưa\n                "
                                        ),
                                        _c("br"),
                                        _c("el-checkbox", {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeCheckboxAllEat(
                                                "lunch",
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.checkboxAllEatLunch,
                                            callback: function ($$v) {
                                              _vm.checkboxAllEatLunch = $$v
                                            },
                                            expression: "checkboxAllEatLunch",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-checkbox", {
                                          model: {
                                            value:
                                              scope.row.attendanceEatKids.lunch,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row.attendanceEatKids,
                                                "lunch",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.attendanceEatKids.lunch",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3782207876
                              ),
                            })
                          : _vm._e(),
                        _vm.configSchoolCommon.afternoonEat
                          ? _c("el-table-column", {
                              attrs: { align: "center", width: "60" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                Chiều\n                "
                                        ),
                                        _c("br"),
                                        _c("el-checkbox", {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeCheckboxAllEat(
                                                "afternoon",
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.checkboxAllEatAfternoon,
                                            callback: function ($$v) {
                                              _vm.checkboxAllEatAfternoon = $$v
                                            },
                                            expression:
                                              "checkboxAllEatAfternoon",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-checkbox", {
                                          model: {
                                            value:
                                              scope.row.attendanceEatKids
                                                .afternoon,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row.attendanceEatKids,
                                                "afternoon",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.attendanceEatKids.afternoon",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2804418429
                              ),
                            })
                          : _vm._e(),
                        _vm.configSchoolCommon.secondAfternoonEat
                          ? _c("el-table-column", {
                              attrs: { align: "center", width: "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                Phụ chiều\n                "
                                        ),
                                        _c("br"),
                                        _c("el-checkbox", {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeCheckboxAllEat(
                                                "secondAfternoon",
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.checkboxAllEatSecondAfternoon,
                                            callback: function ($$v) {
                                              _vm.checkboxAllEatSecondAfternoon =
                                                $$v
                                            },
                                            expression:
                                              "checkboxAllEatSecondAfternoon",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-checkbox", {
                                          model: {
                                            value:
                                              scope.row.attendanceEatKids
                                                .secondAfternoon,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row.attendanceEatKids,
                                                "secondAfternoon",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.attendanceEatKids.secondAfternoon",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2825828592
                              ),
                            })
                          : _vm._e(),
                        _vm.configSchoolCommon.eveningEat
                          ? _c("el-table-column", {
                              attrs: { align: "center", width: "60" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                Tối\n                "
                                        ),
                                        _c("br"),
                                        _c("el-checkbox", {
                                          on: {
                                            change: function ($event) {
                                              return _vm.changeCheckboxAllEat(
                                                "dinner",
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.checkboxAllEatDinner,
                                            callback: function ($$v) {
                                              _vm.checkboxAllEatDinner = $$v
                                            },
                                            expression: "checkboxAllEatDinner",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-checkbox", {
                                          model: {
                                            value:
                                              scope.row.attendanceEatKids
                                                .dinner,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row.attendanceEatKids,
                                                "dinner",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.attendanceEatKids.dinner",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1018903993
                              ),
                            })
                          : _vm._e(),
                        _vm.checkPermission([
                          "studentQuality_attendance_update",
                        ])
                          ? _c("el-table-column", {
                              attrs: {
                                label: "Tác vụ",
                                width: "180",
                                fixed: "right",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.attendanceEatKids.breakfast &&
                                        scope.row.attendanceEatKids
                                          .secondBreakfast &&
                                        scope.row.attendanceEatKids.lunch &&
                                        scope.row.attendanceEatKids.afternoon &&
                                        scope.row.attendanceEatKids
                                          .secondAfternoon &&
                                        scope.row.attendanceEatKids.dinner
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: { width: "94px" },
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleEatRowUnCheckboxAll(
                                                      scope.$index,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Bỏ chọn\n                "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-button",
                                              {
                                                staticStyle: { width: "94px" },
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleEatRowCheckboxAll(
                                                      scope.$index,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Chọn tất cả\n                "
                                                ),
                                              ]
                                            ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "success",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEatRowSave(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.save")) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2579353111
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { float: "right", margin: "15px 0" } },
                      [
                        _vm.checkPermission([
                          "studentQuality_attendance_update",
                        ])
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button-bottom",
                                attrs: {
                                  type: "success",
                                  loading: _vm.loadingButtonSaveEatDate,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEatManyRowSave()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-check",
                                }),
                                _vm._v(
                                  "\n              Lưu điểm danh ăn\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "el-dropdown",
                          { on: { command: _vm.handleCommand } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "button-bottom",
                                attrs: { type: "success" },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("button.exportFile")) +
                                    "\n                "
                                ),
                                _c("i", {
                                  staticClass: "el-icon-caret-bottom",
                                }),
                              ]
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "exportDate" } },
                                  [_vm._v("Xuất theo ngày")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "exportMonth" } },
                                  [_vm._v("Xuất theo tháng\n                ")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "exportCustom" } },
                                  [
                                    _vm._v(
                                      "Xuất file tổng hợp\n                "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "exportMonthDetail" } },
                                  [
                                    _vm._v(
                                      "Xuất file chi tiết tháng\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "button-bottom",
                    staticStyle: { float: "right", margin: "15px 0" },
                    attrs: { type: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.viewCalendarClassMethod()
                      },
                    },
                  },
                  [_vm._v("Xem lịch học\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("AttendanceView", {
        ref: "AttendanceView",
        attrs: { dialogVisible: _vm.showDialogViewDetailDate },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDialogViewDetailDateMethod()
          },
        },
      }),
      _c("AttendanceArriveUpdate", {
        ref: "AttendanceArriveUpdate",
        attrs: { dialogVisible: _vm.showDialogUpdateArrive },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDialogUpdateArriveMethod()
          },
        },
      }),
      _c("AttendanceArriveOfMonth", {
        ref: "AttendanceArriveOfMonth",
        attrs: { dialogVisible: _vm.showDialogDetailArrive },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDialogDetailArriveMethod()
          },
        },
      }),
      _c("AttendanceLeaveUpdate", {
        ref: "AttendanceLeaveUpdate",
        attrs: { dialogVisible: _vm.showDialogUpdateLeave },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDialogUpdateLeaveMethod()
          },
        },
      }),
      _c("AttendanceLeaveOfMonth", {
        ref: "AttendanceLeaveOfMonth",
        attrs: { dialogVisible: _vm.showDialogDetailLeave },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDialogDetailLeaveMethod()
          },
        },
      }),
      _c("AttendanceEatOfMonth", {
        ref: "AttendanceEatOfMonth",
        attrs: { dialogVisible: _vm.showDialogDetailEat },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDialogDetailEatMethod()
          },
        },
      }),
      _c("CreateAttendanceKidCustomExcel", {
        ref: "CreateAttendanceKidCustomExcel",
        attrs: { dialogVisible: _vm.showDialogCreateAttendanceKidCustomExcel },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDialogAttendanceKidCustomExcel()
          },
        },
      }),
      _c("CreateAttendanceKidDetailExcel", {
        ref: "CreateAttendanceKidDetailExcel",
        attrs: { dialogVisible: _vm.showDialogCreateAttendanceKidMonthDetail },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDialogAttendanceKidMonthDetailExcel()
          },
        },
      }),
      _c("DayOffClassDialog", {
        ref: "DayOffClassDialog",
        attrs: { dialogVisible: _vm.showDayOffClassDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDayOffClassDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }