var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.fullNameTitle,
        visible: _vm.dialogVisible,
        width: "750px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "8vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "attendanceKid",
          attrs: {
            "label-width": "120px",
            model: _vm.attendanceKid,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c("el-form-item", [
                      _c(
                        "div",
                        [
                          _vm.attendanceKid.attendanceArriveKids
                            .arriveUrlPicture != null
                            ? _c("el-image", {
                                staticStyle: {
                                  width: "150px",
                                  height: "150px",
                                  "margin-right": "20px",
                                  "margin-top": "20px",
                                },
                                attrs: {
                                  src: _vm.attendanceKid.attendanceArriveKids
                                    .arriveUrlPicture,
                                  fit: "fill",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "22px",
                            color: "#4177e2",
                            "font-size": "17px",
                          },
                        },
                        [
                          _vm._v(
                            "Giờ đến: " +
                              _vm._s(
                                _vm._f("formatTime")(
                                  _vm.attendanceKid.attendanceArriveKids
                                    .timeArriveKid
                                )
                              )
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 16 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content bg-purple" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Nội dung" } },
                      [
                        _c("el-input", {
                          attrs: { rows: 3, type: "textarea", disabled: "" },
                          model: {
                            value:
                              _vm.attendanceKid.attendanceArriveKids
                                .arriveContent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.attendanceKid.attendanceArriveKids,
                                "arriveContent",
                                $$v
                              )
                            },
                            expression:
                              "attendanceKid.attendanceArriveKids.arriveContent",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.attendanceKid.attendanceArriveKids.idCreated != null
                      ? _c("el-form-item", [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "line-height": "10px" } },
                                [
                                  _vm._v(
                                    "Người tạo điểm danh: " +
                                      _vm._s(
                                        _vm.attendanceKid.attendanceArriveKids
                                          .createdBy
                                      )
                                  ),
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "Thời gian tạo: " +
                                    _vm._s(
                                      _vm._f("formatDateTime")(
                                        _vm.attendanceKid.attendanceArriveKids
                                          .createdDate
                                      )
                                    )
                                ),
                              ]),
                            ]
                          ),
                          _vm.attendanceKid.attendanceArriveKids.createdDate !=
                          _vm.attendanceKid.attendanceArriveKids.lastModifieDate
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticStyle: { "line-height": "10px" } },
                                  [
                                    _vm._v(
                                      "Người sửa điểm danh: " +
                                        _vm._s(
                                          _vm.attendanceKid.attendanceArriveKids
                                            .lastModifieBy
                                        )
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(
                                    "Thời gian sửa: " +
                                      _vm._s(
                                        _vm._f("formatDateTime")(
                                          _vm.attendanceKid.attendanceArriveKids
                                            .lastModifieDate
                                        )
                                      )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm.attendanceKid.attendanceLeaveKids.idCreated != null
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("hr"),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-form-item", [
                          _c(
                            "div",
                            [
                              _vm.attendanceKid.attendanceLeaveKids
                                .leaveUrlPicture != null
                                ? _c("el-image", {
                                    staticStyle: {
                                      width: "150px",
                                      height: "150px",
                                      "margin-right": "20px",
                                      "margin-top": "20px",
                                    },
                                    attrs: {
                                      src: _vm.attendanceKid.attendanceLeaveKids
                                        .leaveUrlPicture,
                                      fit: "fill",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-left": "25px",
                                color: "#4177e2",
                                "font-size": "17px",
                              },
                            },
                            [
                              _vm._v(
                                "Giờ về: " +
                                  _vm._s(
                                    _vm._f("formatTime")(
                                      _vm.attendanceKid.attendanceLeaveKids
                                        .timeLeaveKid
                                    )
                                  )
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 16 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Nội dung" } },
                          [
                            _c("el-input", {
                              attrs: {
                                rows: 3,
                                disabled: "",
                                type: "textarea",
                              },
                              model: {
                                value:
                                  _vm.attendanceKid.attendanceLeaveKids
                                    .leaveContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.attendanceKid.attendanceLeaveKids,
                                    "leaveContent",
                                    $$v
                                  )
                                },
                                expression:
                                  "attendanceKid.attendanceLeaveKids.leaveContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.attendanceKid.attendanceLeaveKids.idCreated != null
                          ? _c("el-form-item", [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "10px" } },
                                    [
                                      _vm._v(
                                        "Người điểm danh: " +
                                          _vm._s(
                                            _vm.attendanceKid
                                              .attendanceLeaveKids.createdBy
                                          )
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      "Thời gian tạo: " +
                                        _vm._s(
                                          _vm._f("formatDateTime")(
                                            _vm.attendanceKid
                                              .attendanceLeaveKids.createdDate
                                          )
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.attendanceKid.attendanceLeaveKids
                                .createdDate !=
                              _vm.attendanceKid.attendanceLeaveKids
                                .lastModifieDate
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "line-height": "10px" },
                                      },
                                      [
                                        _vm._v(
                                          "Người sửa điểm danh: " +
                                            _vm._s(
                                              _vm.attendanceKid
                                                .attendanceLeaveKids
                                                .lastModifieBy
                                            )
                                        ),
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        "Thời gian sửa: " +
                                          _vm._s(
                                            _vm._f("formatDateTime")(
                                              _vm.attendanceKid
                                                .attendanceLeaveKids
                                                .lastModifieDate
                                            )
                                          )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("attendanceKid")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }